<template>
  <img class="unselect" @click="login" src="/static/icon/ic_google_circle.svg">
</template>

<script>
  export default {
    name: "BtnSigninGoogle",
    methods: {
      login() {
        const url = encodeURIComponent(`${this.backendUrl}/auth/user/signin/google/launchpack`);

        const scope = "https://www.googleapis.com/auth/userinfo.email " +
                "https://www.googleapis.com/auth/userinfo.profile";
        location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=850496551254-9e5rep63vauttf5586o78fvfrts5gnfi.apps.googleusercontent.com&response_type=code&redirect_uri=${url}&scope=${scope}`
      }
    }
  }
</script>

<style lang="stylus" scoped>
  img
    width 52px
    height 52px
</style>
