<template>
  <div class="container-top bg">
    <!-- pc -->
    <div class="pc signin-container">
      <div class="subtitle3 main" style="margin-bottom: 40px">로그인</div>
      <div v-for="(form, idx) in formData" :key="`form-${idx}`">
        <div class="signin-label">{{ form.label }}</div>
        <component
          :is="form.component_name"
          v-bind.sync="form"
          @onEnter="signin"
          class="margin-top-8"
          :key="`form-${idx}`"
        ></component>
      </div>
      <button class="button is-primary" style="width:100%;margin-top:40px" @click="signin">로그인</button>
      <div class="flex-center" style="margin-top:12px;gap:24px">
        <div class="body3 sub2 unselect" @click="routerPush('/findpw')">비밀번호 찾기</div>
        <div class="body3 sub2 unselect" @click="routerPush('/signup')">회원가입</div>
        <div class="body3 sub2 unselect" @click="routerPush('/partner_signup')">파트너가입</div>
      </div>
      <div class="position-relative margin-top-28">
        <div class="divider"></div>
        <div class="flex-center">
          <div class="body5 sub3 other">또는</div>
        </div>
        <div class="body4 sub2 margin-top-28">SNS으로 로그인/회원가입</div>
        <sns-signin></sns-signin>
      </div>
    </div>

    <!-- mobile -->
    <div class="mobile signin-container">
      <div class="h7 main" style="margin-bottom: 32px">로그인</div>
      <div v-for="(form, idx) in formData" :key="`form-${idx}`">
        <div class="signin-label">{{ form.label }}</div>
        <component
          :is="form.component_name"
          v-bind.sync="form"
          @onEnter="signin"
          class="margin-top-8"
          :key="`form-${idx}`"
        ></component>
      </div>
      <button class="button is-primary body2-medium" style="width:100%;margin-top:40px;height:48px" @click="signin">
        로그인
      </button>
      <div class="flex-center" style="margin-top:12px;gap:24px">
        <div class="body3 sub2 unselect" @click="routerPush('/findpw')">비밀번호 찾기</div>
        <div class="body3 sub2 unselect" @click="routerPush('/signup')">회원가입</div>
        <div class="body3 sub2 unselect" @click="routerPush('/partner_signup')">파트너가입</div>
      </div>
      <div class="position-relative margin-top-28">
        <div class="divider"></div>
        <div class="flex-center">
          <div class="body5 sub3 other">또는</div>
        </div>
        <div class="body4 sub2 margin-top-28">SNS으로 로그인/회원가입</div>
        <sns-signin :signin="true"></sns-signin>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '../../mixins/FormMixin';
import LpText from '../component/LpText';
import SnsSignin from '../component/SnsSignin';

export default {
  name: 'Signin',
  components: { SnsSignin, LpText },
  mixins: [FormMixin],
  created() {},
  data() {
    return {
      formData: [
        {
          label: '이메일',
          placeholder: 'example@gmail.com',
          maxLength: 100,
          field: 'username',
          type: 'email',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '비밀번호',
          placeholder: '',
          maxLength: 30,
          field: 'password',
          type: 'password',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            valid_required: true,
            required: true,
          },
        },
      ],
    };
  },
  methods: {
    signin() {
      let result = this.getParamData(this.formData);
      if (result.enable) {
        this.$axios
          .post('auth/user/login', result.params)
          .then((res) => {
            if (res.status === 200) {
              this.$store.commit('setUser', res.data.user);
              this.setAuthHeader();
              if (this.$store.getters.prevPath === '') {
                this.$router.push('mypage/my_service');
              } else if (this.$store.getters.trial && this.$store.getters.basket) {
                this.$router.push('/service_survey');
              } else {
                let path = this.$store.getters.prevPath;
                this.$store.commit('setPrevPath', '');
                this.$router.replace(path);
              }
              this.setGa('로그인', '응답', '로그인');
            }
          })
          .catch((error) => {
            if (error.response) {
              let res = error.response;
              if (res.status !== 200) {
                this.toast(res.data.detail);
              }
            }
          });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.bg
  background-color $gray4
  display flex
  justify-content center
  align-items flex-start
.signin-container
  border 1px solid $gray2
  border-radius 12px
  background-color white
  padding 40px
  margin-top 80px
  width 448px
  text-align center
.signin-label
  text-align left
  margin-top 16px
  color $sub3
  font-size 15px

.divider
  position absolute
  width 100%
  height 1px
  background-color $gray1
  top 8px
.other
  z-index 1
  background-color white
  padding 0 20px


@media (max-width:1024px)
  .signin-container
    margin 40px 16px
    padding 32px 20px
  .signin-label
    font-size 14px
</style>
